@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c_light;
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    align-items: center;

    @include tablet_portrait {
        padding: 0 20px;
    }
    @include mobile {
        position: relative;
    }
}
