#nprogress {
    .bar {
        background: $c_main;
        height: 3px;
    }

    .peg {
        box-shadow: 0 0 10px $c_main, 0 0 5px $c_main;
    }
}

.nprogress__custom {
    overflow: hidden;
    border-radius: 4px;
    #nprogress {
        transition: none;

        .bar {
            background-color: $c_main;
            height: 100%;
            border-radius: 4px;
        }
        .peg {
            box-shadow: none;
        }
    }
}
