// Fonts Roboto
// ==========================================================================
@import url("https://use.typekit.net/nze2plc.css");

// Fonts
// ==========================================================================
$f_hx: "raleway", Sans-Serif;
$f_txt: "raleway", Sans-Serif;
$f_spe: "ff-market-web", Sans-Serif;

// Colors
// ==========================================================================
$c_main: #103587;
$c_main_light: #70ae2d;
$c_sub: #e7333f;
$c_third: #473978;
$c_fourth: #103587;
$c_green: #339e37;
$c_grey: #333333;
$c_txt: #434343;
$c_light: #f5f5f5;
$c_dark: #000000;
$c_greydark: #666666;
$c_white: #ffffff;
$c_red: #ff1a7b;
$c_error: #e91741;
$c_success: #4bb543;

$c_attendus_main: #f8c04a;
$c_attendus_main_sub: #fde9bb;
$c_attendus_details: #5ca23b;
$c_attendus_details_sub: #c6dcaf;
$c_attendus_justificatifs: #81b938;
$c_attendus_justificatifs_sub: #d6e5b2;
$c_attendus_informations: #0a7f43;
$c_attendus_informations_sub: #bbd4ad;

$c_or: #edd367;
$c_argent: #a0a0a0;
$c_argent_sub: #e0e0e0;
$c_bronze: #ba823f;
$c_bronze_sub: #fad8ad;

//Espace perso
$c_perso_bleu: #538fcc;

// Sizes
// ==========================================================================
$s_h1: 34px;
$s_h2: 28px;
$s_h3: 20px;
$s_p: 16px;
$s_link: 14px;

// Width / Height / Spacing
// ==========================================================================
$max-width: 1120px;
$border-gutter: 40px;
$content-spacing: 100px;
$content-gutter: 30px;
$header-height: 120px;
$copyright-height: 60px;
$grid-extra-small: 780px;
$grid-small: 960px;
$grid-medium: 1120px;
$max-width-large: 1600px - $border-gutter - $border-gutter;

// Breakpoints
// ==========================================================================
$breakpoint_wd: 1600px;
$breakpoint_paysage: 1024px;
$breakpoint_portrait: 980px;
$breakpoint_mo: 599px;
$breakpoint_mo_small: 320px;

$cubic_bezier: cubic-bezier(0.8, 0.09, 0.22, 0.88);
