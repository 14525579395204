@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.header-button {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding: 13px 20px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid rgba($c_txt, 0.2);
    cursor: pointer;
    @include addTransition();
    font-family: $f_hx;
    color: $c_txt;
    &:not(.last-child) {
        margin-right: 10px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 10px;
        line-height: 12px;
        padding: 10px 13px;
        &.small-mobile {
            padding: 10px;
            font-size: 0;
            span {
                margin-right: 0;
            }
        }
    }

    span {
        font-size: 14px;
        line-height: 1;
        margin-right: 10px;
        font-weight: 400;
    }

    &.classic {
        background-color: white;
    }
    &.full {
        background-color: $c_light;
        border-color: $c_light;
    }

    &.check-online {
        pointer-events: none;
        span {
            width: 10px;
            height: 10px;
            background: #d8d8d8;
            border-radius: 50%;
        }
    }

    &.is-online {
        span {
            background: #339e37;
        }
    }

    &.disabled {
        pointer-events: none;
    }

    &:hover {
        background-color: $c_main;
        color: white !important;
    }
}
