@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.footer-zone {
    display: flex;
    align-items: center;
    &--button {
        background: transparent;
        border: none;
        outline: none;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
        @include addTransition();

        &:hover {
            color: $c_main;
        }
    }

    &--copyright {
        font-size: 12px;
        line-height: 18px;
        margin: auto;
        &:before {
            content: "- ";
            display: inline-block;
            position: relative;
            font-size: 12px;
            line-height: 18px;
            text-decoration: none;
            margin-right: 5px;
        }
    }
}
.footer-button {
}
