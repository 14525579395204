@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";
@import "../../styles/_forms.scss";

.loginPage {
    overflow: hidden;
    margin-top: 170px;
    padding-bottom: 40px;
    &:before {
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        width: 1044px;
        height: 1010px;

        background: url(../../assets/images/bg-dashboard.png);
        background-repeat: no-repeat;
        background-position: bottom -456px left -408px;
        background-size: 1044px 1010px;
        pointer-events: none;
        mix-blend-mode: multiply;
        z-index: -1;
    }

    &__container {
        position: relative;
        background-color: $c_light;
        width: 620px;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 20px;
        padding: 40px;

        form {
            label {
                span {
                    margin-bottom: 20px;
                }
                input {
                    margin-bottom: 30px;
                }
            }
        }
    }

    #loading {
        position: relative;
        margin: 0 auto;
        width: 300px;
        height: 10px;
        margin-top: 20px;
        display: none;
    }

    &__chargement {
        height: auto;
        display: block;
        text-align: center;
        text-align: center;

        font-family: $f_txt;
        color: $c_main;
        font-weight: 900;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 1px;
        + #loading {
            display: block;
        }
    }
}
