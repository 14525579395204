@import "../../styles/_variables";
@import "../../styles/_mixins";

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;

    &__shadow {
        background: rgba(black, 0.25);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity 250ms linear;
    }

    &__content {
        background: white;
        z-index: 2;
        width: 640px;
        max-width: 100%;
        padding: 45px 40px;
        position: relative;
        height: 100%;
        overflow-y: auto;
        transform: translateX(100%);
        transition: transform 250ms $cubic_bezier;

        .btn-close {
            outline: none;
            border: none;
            background: transparent;
            position: absolute;
            top: 50px;
            right: 40px;
            cursor: pointer;
            @include addTransition();
            &:hover {
                color: $c_main;
            }
        }
    }

    &__title {
        margin-bottom: 35px;
        &__hover {
            position: relative;
            cursor: auto;
            text-transform: capitalize;
            &:after {
                content: "\e910";
                @include font();
                font-size: 14px;
                line-height: 1;
                vertical-align: top;
                display: inline-block;
                margin-left: 2px;
            }

            .tooltip {
                position: absolute;
                width: 260px;
                height: auto;
                top: calc(100% + 11px);
                left: 50%;
                margin-left: -130px;
                padding: 20px;
                right: auto;
                display: block;
                color: white;
                background-color: $c_main;
                border-radius: 8px;
                font-size: 12px;
                line-height: 15px;
                text-transform: none;
                z-index: 50;
                font-weight: 300;
                opacity: 0;
                pointer-events: none;
                @include addTransition;

                &:before {
                    content: "";
                    position: absolute;
                    top: -10px;
                    left: 50%;
                    margin-left: -11px;
                    display: inline-block;
                    height: 0;
                    width: 0;
                    border-right: 11px solid transparent;
                    border-bottom: 11px solid $c_main;
                    border-left: 11px solid transparent;
                }
            }

            &:hover {
                .tooltip {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    &__children {
        background: $c_light;
        padding: 30px;
        border-radius: 15px;

        textarea {
            height: auto;
            background-color: transparent;
            width: 100%;
            border: none;
            outline: none;
            resize: none;
            overflow: visible;
        }
    }

    &.is-active {
        pointer-events: auto;

        .overlay__shadow {
            opacity: 1;
            transition: opacity 250ms linear;
        }

        .overlay__content {
            transform: translateX(0%);
            transition: transform 250ms $cubic_bezier;
        }
    }
}
