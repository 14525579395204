form {
    label {
        span {
            font-family: $f_txt;
            display: block;
            font-weight: 900;
            color: $c_main;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    input[type="text"],
    input[type="password"],
    textarea {
        box-sizing: border-box;
        font-family: $f_txt;
        display: block;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        color: rgba($c_txt, 0.5);
        padding: 10px 0;
        background: transparent;
        border: 1px solid rgba($c_main, 0.3);
        border-radius: 10px;
        padding: 17px 20px;
        resize: none;
        &:placeholder {
            font-family: $f_txt;
            font-size: 12px;
            line-height: 16px;
            color: rgba($c_txt, 0.5);
        }
    }
}
