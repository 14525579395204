@import "../../styles/variables";
@import "../../styles/mixins";

.saving-audit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.25);
    pointer-events: none;
    opacity: 0;
    @include addTransition(opacity, 250ms, linear);

    &__content {
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px 100px 35px 100px;
        border-radius: 25px;
        transform: translateY(50px);
        @include addTransition(transform, 250ms, ease-in-out);

        .text {
            font-family: $f_txt;
            color: $c_main;
            font-weight: 900;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
    }

    &.is-active {
        opacity: 1;
        @include addTransition(opacity, 250ms, linear);
        pointer-events: auto;

        .saving-audit__content {
            transform: translateY(0px);
            @include addTransition(transform, 250ms, ease-in-out);
        }
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $c_main;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $c_main transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
