@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.audit-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10px;
    padding: 10px 30px;
    box-sizing: border-box;
    @include addTransition();

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    .left-part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        margin-right: 10px;
        padding-right: 30px;
        border-right: 1px solid rgba(#707070, 0.2);

        &__title {
            @include ft($f_hx, 14px, 700, $c_main, 20px);
        }

        &__infos {
            display: flex;
            align-items: center;

            .infos__label {
                @include ft($f_hx, 12px, 700, white, 14px);
                border-radius: 12px;
                padding: 5px 15px;
                background: black;
                text-transform: capitalize;
                display: block;
                margin-right: 30px;

                &.or {
                    background: $c_or;
                }

                &.bronze {
                    background: $c_bronze;
                }

                &.argent {
                    background: $c_argent;
                }
            }

            .icon-arrow-small-right {
                color: $c_txt;
                font-size: 18px;
            }
        }
    }

    .right-part {
        width: 40px;
        height: 40px;
        outline: none;
        border: 0;
        border-radius: 50%;
        background: rgba(#434343, 0.1);
        cursor: pointer;
        @include addTransition();

        span {
            font-size: 16px;
            color: $c_txt;
        }

        &:hover {
            background-color: $c_main;

            span {
                color: white;
            }
        }
    }

    &.audits-en-cours {
        background-color: #eaeaeb;
        .left-part {
            &__title {
                color: $c_txt;
            }
        }
    }

    &:hover {
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
    }
}
