@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_forms.scss";
@import "./../../../styles/_text.scss";

.login-response {
    @extend .btn;
    background: $c_success;
    border: 1px solid $c_success;
    font-family: $f_txt;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
    position: absolute;
    bottom: 40px;
    right: 40px;
    cursor: default;

    &:hover,
    &:active {
        background: $c_success;
        border: 1px solid $c_success;
    }

    &.is-error {
        background: $c_error;
        border: 1px solid $c_error;

        &:hover,
        &:active {
            background: $c_error;
            border: 1px solid $c_error;
        }
    }
}
