a {
    text-decoration: none;
    color: $c_main;
    &:hover,
    &:focus {
        text-decoration: none;
        body:not(.no-hover) & {
            color: $c_greydark;
        }
    }
}

// Basics
// ==========================================================================
h1 {
    position: relative;
    display: block;
    margin: 0 0 20px 0;
    @include ft($f_hx, $s_h1, 700, $c_main, $s_h1 + 10);
}

h2 {
    position: relative;
    display: block;
    margin: 0 0 20px 0;
    @include ft($f_hx, $s_h2, 700, $c_main, $s_h2 + 10);
}

h3 {
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    @include ft($f_hx, $s_h3, 700, $c_main, $s_h3 + 10);
    text-transform: uppercase;
    letter-spacing: 1px;
}

p {
    position: relative;
    display: block;
    margin: 0 0 20px 0;
    @include ft($f_txt, $s_p, 400, $c_txt, $s_p + 10);
}

ul {
    position: relative;
    display: block;
    list-style-type: none;
    li {
        position: relative;
        padding: 0;
        @include ft($f_txt, $s_p, 400, $c_txt, $s_p + 5);
        a {
            color: $c_sub;
        }
    }
}

ol {
    position: relative;
    display: block;
    list-style-type: decimal;
    li {
        position: relative;
        padding: 0;
        @include ft($f_txt, $s_p, 400, $c_txt, $s_p + 5);
        a {
            color: $c_sub;
        }
    }
}

blockquote {
    @include ft($f_txt, 16px, 400, $c_txt, 22px);
    padding: 10px 15px;
    margin: 10px 0 10px 50px;
    border-left: 2px solid $c_main;
    position: relative;
    @include mobile {
        padding: 10px 0 10px 25px;
        margin: 15px 0 20px 0;
    }
    p {
        margin-bottom: 0;
    }
}

.btn {
    box-sizing: border-box;
    display: inline-block;
    color: white;
    background-color: $c_main;
    border: 1px solid $c_main;
    font-weight: 900;
    font-size: 11px;
    line-height: 16px;
    padding: 22px 50px;
    border-radius: 90px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    @include addTransition;

    &:hover {
        background-color: $c_bronze;
        border-color: $c_bronze;
    }

    &:disabled {
        background: rgba($c_main, 0.5);
        border: 1px solid rgba($c_main, 0.1);
        pointer-events: none;
    }
}
