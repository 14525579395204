@import "../../../../../styles/variables";

.audit-etape__fieldset {
    box-sizing: border-box;
    &:not(:last-child) {
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(#000, 0.2);
        padding-bottom: 40px;
    }

    legend {
        font-family: $f_txt;
        font-size: 12px;
        line-height: 13px;
        text-transform: uppercase;
        font-weight: 900;
        color: $c_main;
        letter-spacing: 1px;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
            font-size: 11px;
            line-height: 12px;
        }
    }

    &__radio {
        display: flex;
        justify-content: space-between;
        input {
            display: none;
            &:checked {
                + label {
                    background-color: $c_main;
                    border-color: $c_main;
                    color: white;
                }
            }
        }
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(33.3333% - 13.3333px);
            height: 100px;
            border: 1px solid rgba($c_txt, 0.3);
            border-radius: 10px;
            cursor: pointer;
            padding: 10px;
            background-color: rgba(#d5d5d5, 0.3);

            font-family: $f_txt;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 16px;
            line-height: 20px;
            color: rgba($c_txt, 0.3);
            text-align: center;

            @media screen and (max-width: 1024px) {
                font-size: 12px;
                line-height: 14px;
                height: 70px;
            }
        }
    }

    &__textarea {
        display: block;
        margin-top: 20px;
        label {
            display: none;
        }
        textarea {
            box-sizing: border-box;
            font-size: 14px;
            line-height: 22px;
            color: $c_txt;
            &:placeholder {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
