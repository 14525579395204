@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.error-boundary {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 80px;
    background: white;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-weight: 600;

    .text {
        @include ft($f_hx, 14px, 500, $c_main, 24px);

        &--big {
            font-size: 18px;
        }

        &--link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
