@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.attendus-dropdown {
    overflow: hidden;
    background: white;
    border-radius: 5px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &.is-active {
        .attendus-dropdown__attendu {
            display: flex;

            &--main:before {
                transform: rotate(180deg);
                transition: transform 250ms $cubic_bezier;
            }
        }
    }

    &__attendu {
        display: none;
        background: $c_attendus_main_sub;

        span {
            display: block;
            @include ft($f_hx, 14px, 700, $c_txt, 18px);
            box-sizing: border-box;

            &.attendu__title {
                width: 130px;
                text-transform: uppercase;
                font-weight: 900;
                padding: 18px;
                font-size: 12px;
                line-height: 16px;
                background: $c_attendus_main;
                color: white;
            }

            &.attendu__content {
                flex: 1;
                padding: 15px 10px;
                font-weight: 400;
                white-space: pre-line;
            }
        }

        &--main {
            display: flex;
            position: relative;
            cursor: pointer;

            &:before {
                content: "\e90b";
                @include font();
                position: absolute;
                top: 18px;
                right: 18px;
                pointer-events: none;
                transform: rotate(0deg);
                transition: transform 250ms $cubic_bezier;
                font-size: 12px;
            }

            span.attendu__content {
                font-weight: 700;
                padding-right: 50px;
            }
        }

        &--details {
            background: $c_attendus_details_sub;

            span.attendu__title {
                background: $c_attendus_details;
            }
        }

        &--justificatifs {
            background: $c_attendus_justificatifs_sub;

            span.attendu__title {
                background: $c_attendus_justificatifs;
            }
        }

        &--informations {
            background: $c_attendus_informations_sub;

            span.attendu__title {
                background: $c_attendus_informations;
            }
        }
    }

    &.argent {
        .attendus-dropdown__attendu {
            &--main {
                background: $c_argent_sub;

                span.attendu__title {
                    background: $c_argent;
                }
            }
        }
    }

    &.bronze {
        .attendus-dropdown__attendu {
            &--main {
                background: $c_bronze_sub;

                span.attendu__title {
                    background: $c_bronze;
                }
            }
        }
    }
}
