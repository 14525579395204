@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

#header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    padding: 0px 20px;
    z-index: 500;
    background-color: white;

    .left-part {
        a {
            display: flex;
            align-items: center;

            img {
                margin-right: 0;
                height: 70px;
                width: auto;
            }
            .text {
                font-family: $f_txt;
                font-size: 12px;
                line-height: 14px;
                color: $c_txt;
                margin-left: 20px;
                width: 200px;

                &.golf {
                    color: $c_main;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 300;
                    @media screen and (max-width: 1024px) {
                        font-size: 13px;
                        line-height: 17px;
                    }
                    b {
                        display: block;
                        font-weight: 900;
                        font-size: 24px;
                        left: 26px;
                        @media screen and (max-width: 1024px) {
                            font-size: 20px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }

    .right-part {
        display: flex;
        align-items: center;
    }
}
