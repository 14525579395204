@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.audit-menu-item {
    position: relative;
    font-family: $f_txt;
    cursor: pointer;
    font-size: 13px;
    line-height: 15px;
    color: rgba($c_main, 0.3);
    @include addTransition;
    &:not(:last-child) {
        margin-bottom: 30px;
    }

    &:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: -4px;
        left: -30px;
        width: 22px;
        height: 22px;
        background-color: $c_white;
        border-radius: 50%;
    }
    &:after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: -1px;
        left: -30px;
        width: 16px;
        height: 16px;
        background-color: $c_white;
        border-radius: 50%;
        border: 1px solid rgba($c_main, 0.3);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7px;
    }

    &:hover {
        color: $c_main;
    }

    &.is-valid {
        color: $c_success;
        &:after {
            border: 4px solid $c_success;
            background-color: $c_success;
            content: "\e912";
            @include font();
            color: white;
        }
    }

    &.is-error {
        color: $c_error;

        &:after {
            border: 4px solid $c_error;
            background-color: $c_error;
            content: "\e905";
            @include font();
            color: white;
        }
    }

    &.is-active {
        font-weight: 700;
        color: $c_main;
        position: relative;

        &.is-error {
            .error {
                display: block;
            }
        }

        &:after {
            border: 4px solid $c_main;
            background-color: white;
        }
    }

    .error {
        display: none;
        position: absolute;
        width: 200px;
        height: auto;
        bottom: calc(100% + 11px);
        left: 50%;
        margin-left: -130px;
        padding: 20px;
        right: auto;
        color: white;
        background-color: $c_error;
        border-radius: 8px;
        font-size: 12px;
        line-height: 15px;
        text-transform: none;
        z-index: 50;
        font-weight: 300;
        @include addTransition;

        &:before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -11px;
            display: inline-block;
            height: 0;
            width: 0;
            border-top: 11px solid $c_error;
            border-right: 11px solid transparent;
            border-left: 11px solid transparent;
        }

        .icon-close {
            pointer-events: none;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 10px;
        }
    }

    &:nth-child(-n + 3) {
        .error {
            bottom: auto;
            top: calc(100% + 11px);
            &:before {
                top: -10px;
                bottom: auto;
                border-top: none;
                border-right: 11px solid transparent;
                border-bottom: 11px solid $c_error;
                border-left: 11px solid transparent;
            }
        }
    }
}
