@import "./../../styles/_variables";
@import "./../../styles/_mixins";

.disconnect-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        opacity: 0;
        transition: opacity 250ms linear;
        pointer-events: none;
        background: white;
        position: relative;
        z-index: 2;
        width: auto;
        top: auto;
        left: auto;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 100px 120px 60px 120px;
        border-radius: 25px;
        transform: translateY(50px);
        @include addTransition(all, 250ms, ease-in-out);

        h2 {
            font-weight: 900;
            font-size: 32px;
            text-align: center;
            line-height: 32px;
            margin-bottom: 25px;
        }

        p {
            color: $c_main;
            text-align: center;
            margin-bottom: 40px;
        }

        button {
            margin-bottom: 10px;
            font-weight: 900;
            font-family: $f_hx;
            font-size: 12px;
            background: $c_green;
            border: 1px solid $c_green;

            &.stay {
                margin-bottom: 0;
                background: $c_txt;
                border: 1px solid $c_txt;
            }
        }

        .icon-close {
            padding: 20px;
            cursor: pointer;
            position: absolute;
            top: 25px;
            right: 25px;
            font-size: 14px;
            transform: rotate(0deg);
            transition: transform 500ms $cubic_bezier;

            &:hover {
                transform: rotate(180deg);
                transition: transform 500ms $cubic_bezier;
            }
        }
    }

    &.is-active {
        .disconnect-modal__content {
            transform: translateY(0px);
            opacity: 1;
            pointer-events: auto;
            @include addTransition(all, 250ms, ease-in-out);
        }
    }
}
