@import "../../../../styles/variables";

.audit-menu {
    position: relative;
    padding-left: 30px;
    width: 230px;
    margin-right: 40px;
    flex-shrink: 0;
    margin-bottom: auto;
    @media screen and (max-width: 1024px) {
        width: 180px;
        margin-right: 20px;
    }
    &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        left: 8px;
        background-color: $c_main;
        opacity: 0.3;
    }
}
