@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.audits-homepage {
    overflow: hidden;
    position: relative;
    display: block;
    margin-top: 130px;
    padding-bottom: 40px;
    &:before {
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        width: 1044px;
        height: 1010px;

        background: url(../../../assets/images/bg-dashboard.png);
        background-repeat: no-repeat;
        background-position: bottom -456px left -408px;
        background-size: 1044px 1010px;
        pointer-events: none;
        mix-blend-mode: multiply;
        z-index: -1;
    }

    &__wrapper {
        background-color: $c_light;
        width: 620px;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 20px;
        padding: 40px;
    }
    &__header {
        h1 {
            font-weight: 300;
            margin-bottom: 40px;

            strong {
                font-weight: 900;
                font-size: $s_h1 + 16;
            }
        }

        h3 {
            margin-bottom: 40px;
        }
    }

    &__listing {
        .listing-audits {
            &:not(:last-child) {
                margin-bottom: 40px;
            }

            &__title {
                display: block;
                text-transform: uppercase;
                letter-spacing: 1px;
                @include ft($f_hx, 13px, 900, $c_main, 24px);
                margin-bottom: 15px;
            }
        }
    }
}
