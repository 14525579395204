@import "../../../../../styles/variables";

.audit-etape__ancres {
    width: 230px;
    margin-left: 40px;
    flex-shrink: 0;
    margin-bottom: auto;
    position: sticky;
    top: 140px;

    @media screen and (max-width: 1024px) {
        width: 180px;
        margin-left: 20px;
    }

    ul {
        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            a {
                font-family: $f_txt;
                color: rgba($c_main, 0.3);
                font-size: 12px;
                line-height: 14px;
                display: inline-block;
                &:hover,
                &:focus {
                    color: $c_main;
                }
            }

            &.is-active {
                a {
                    font-weight: 700;
                    color: $c_main;
                }
            }
        }
    }
}
