@import "../../../../styles/variables";

.audit-etape {
    display: flex;
    width: 100%;

    &__fields {
        width: 100%;
        background-color: $c_light;
        padding: 40px;
        border-radius: 20px;
        @media screen and (max-width: 1024px) {
            padding: 20px;
        }
    }
    &__titre {
        font-size: 20px;
        line-height: 25px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 21px;
        }
    }
}
