@import "../../../styles/_variables.scss";

.audit-view {
    margin: 150px 60px 40px 60px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        margin: 150px 30px 0 30px;
    }

    &__form {
        width: 100%;
    }
}

.audit-view__ended {
    overflow: hidden;
    position: relative;
    display: block;
    margin-top: 130px;
    padding-bottom: 40px;
    min-height: calc(100vh - 200px);

    &:before {
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        width: 1044px;
        height: 1010px;

        background: url(../../../assets/images/bg-dashboard.png);
        background-repeat: no-repeat;
        background-position: bottom -456px left -408px;
        background-size: 1044px 1010px;
        pointer-events: none;
        mix-blend-mode: multiply;
        z-index: -1;
    }

    &__wrapper {
        background-color: $c_light;
        width: 620px;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 20px;
        padding: 100px;
        text-align: center;
    }

    span[class^="icon-"] {
        color: $c_main;
        font-size: 88px;
        margin-bottom: 20px;
        display: block;
    }

    h1 {
        font-size: 30px;
        font-weight: 900;
        line-height: 35px;
        margin-bottom: 10px;
    }

    .sous-titre {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: $c_main;
        margin-bottom: 10px;
        b {
            font-weight: 700;
        }
    }

    .cotation {
        display: block;
        text-align: center;
        margin-bottom: 40px;
        span {
            display: inline-block;
            font-family: $f_txt;
            color: white;
            font-size: 12px;
            font-weight: 14px;
            font-weight: 700;
            padding: 5px 15px;
            border-radius: 12px;
            &.or {
                background-color: $c_or;
            }
            &.argent {
                background-color: $c_argent;
            }
            &.bronze {
                background-color: $c_bronze;
            }
        }
    }

    .description {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: $c_txt;
        margin-bottom: 40px;
    }

    .btn {
        font-family: $f_txt;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;
        background-color: $c_green;
        border-color: $c_green;
        &:hover {
            background-color: $c_main;
            border-color: $c_main;
            color: white;
        }
    }
}
