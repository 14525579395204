/* ------------------
--- MIXINS ---
------------------ */

@mixin ft($family: "", $size: "", $weight: "", $color: "", $lineheight: "")
    // define font
 {
    @if $family != "" {
        font-family: $family;
    }
    @if $size != "" {
        font-size: $size;
    }
    @if $weight != "" {
        font-weight: $weight;
    }
    @if $color != "" {
        color: $color;
    }
    @if $lineheight != "" {
        line-height: $lineheight;
    }
}

@mixin absolute() {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

@mixin font() {
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

@mixin ellipsis($number: "", $lineheight: "") {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    @if $number != "" {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $number;
    }
    @if $lineheight != "" {
        line-height: $lineheight;
        max-height: $lineheight * $number;
    }
}

// Define default font size
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// Mixin Breakpoints
// ==========================================================================
@mixin wide {
    @media screen and (min-width: #{$breakpoint_wd}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: #{$max-width + $border-gutter + $border-gutter}) {
        @content;
    }
}

@mixin tablet_landscape {
    @media screen and (max-width: #{$breakpoint_paysage}) {
        @content;
    }
}

@mixin tablet_portrait {
    @media screen and (max-width: #{$breakpoint_portrait}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$breakpoint_mo}) {
        @content;
    }
}

@mixin mobile_small {
    @media screen and (max-width: #{$breakpoint_mo_small}) {
        @content;
    }
}

// Approche Mobile First
// ==========================================================================
@mixin min-mobile {
    @media screen and (min-width: #{$breakpoint_mo}) {
        @content;
    }
}

@mixin min-tablet_portrait {
    @media screen and (min-width: #{$breakpoint_portrait}) {
        @content;
    }
}

@mixin min-tablet_landscape {
    @media screen and (min-width: #{$breakpoint_paysage}) {
        @content;
    }
}

@mixin min-desktop {
    @media screen and (min-width: #{$breakpoint_desktop}) {
        @content;
    }
}

@mixin min-large-desktop {
    @media screen and (min-width: #{$breakpoint_wd}) {
        @content;
    }
}

// Mixin Transition effect
// ==========================================================================
@mixin addTransition($effect: all, $duration: 0.2s, $easeing: ease) {
    $anim: $effect $duration $easeing;
    transition: $anim;
}

// Mixin Aspect-ratio pour les images
// ==========================================================================
@mixin aspect-ratio($width, $height, $add: 0px) {
    position: relative;
    overflow: hidden;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-bottom: $add;
        padding-top: ($height / $width) * 100%;
    }
    > .content,
    > .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// ==========================================================================
// Mixin Two-Color pour les texte qui se coupent en hauteur en deux couleurs différentes
// COUPURE VERTICALE
/*
*	$lheight = Line height
*	$nbl = Nombre de lignes à faire apparraître dans la première couleur
*	$c1 = Première couleur
*	$c2 = Première couleur
*/
@mixin two-color-v($lheight, $nbl, $c1: $c_main, $c2: $c_sub) {
    position: relative;
    color: transparent;

    &:before,
    &:after {
        content: attr(data-title);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:before {
        z-index: 10;
        max-height: $lheight * $nbl;
        color: $c1;
        overflow: hidden;
    }
    &:after {
        z-index: 5;
        color: $c2;
    }
}

// ==========================================================================
// Mixin Two-Color pour les texte qui se coupent en largeur en deux couleurs différentes
// COUPURE HORIZONTAL
/*
*	$theW = La width (peut être un nombre fixe en px ou un calc)
*	$c1 = Première couleur
*	$c2 = Première couleur
*/
@mixin two-color-h($theW, $c1: $c_main, $c2: $c_sub) {
    position: relative;
    color: transparent;

    &:before,
    &:after {
        content: attr(data-title);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:before {
        z-index: 10;
        max-width: $theW;
        color: $c1;
        overflow: hidden;
    }
    &:after {
        z-index: 5;
        color: $c2;
    }
}

// Card à l'Horizontale
// ==========================================================================
@mixin card-horizontale() {
    width: 100%;
    min-height: 120px;
    @include addTransition();

    &.card-non-partenaire {
        background: #f7f8f9;
        .caption {
            background: #f7f8f9;
            width: 100%;
            padding: 20px;
        }
    }

    > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .image {
        position: relative;
        width: 120px;
        height: 100%;
        margin: 0;
        float: left;
        @include aspect-ratio(1, 1);
        box-shadow: 10px 10px 20px rgba($c_dark, 0.1);
        figure,
        .no-photo {
            &:before,
            &:after {
                content: none;
            }
        }
    }

    .date-event {
        display: none;
    }

    .caption {
        width: calc(100% - 120px);
        padding: 5px 5px 5px 20px;
        display: block;
        float: left;
        @include mobile_small {
            padding: 5px 5px 5px 10px;
        }

        .caption {
            padding: 0;
        }

        .title-card {
            font-size: calculateRem(14px);
            line-height: calculateRem(18px);
            margin: 0;
            @include ellipsis(2, 18px);
        }

        .date-article,
        .location,
        .date-agenda {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            margin-bottom: 4px;
        }

        .cat {
            margin-bottom: 0;
            & + .date-agenda {
                margin-top: -5px;
            }
        }
        .location,
        .date-article {
            margin-top: 5px;
        }

        p {
            display: none;
        }
    }
}
